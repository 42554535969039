import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/nextjs/src/app/ClientPolyfills.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/nextjs/src/components/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/nextjs/src/components/FAQSectionItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/nextjs/src/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/nextjs/src/components/GaTrackingNotifications.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/nextjs/src/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/nextjs/src/components/ScrollableBlogPosts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/nextjs/src/components/SearchInput.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TailwindToaster"] */ "/app/apps/nextjs/src/components/TailwindToaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalStateProvider"] */ "/app/apps/nextjs/src/hooks/useGlobalState.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyTestsStateProvider"] */ "/app/apps/nextjs/src/hooks/useMyTestsState.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/nextjs/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/app/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/aeonik/aeonik-regular.woff\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../fonts/aeonik/aeonik-medium.woff\",\"weight\":\"bold\",\"style\":\"normal\"}],\"adjustFontFallback\":\"Arial\",\"fallback\":[\"Arial\"],\"variable\":\"--font-aeonik\"}],\"variableName\":\"Aeonik\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/ivardisplay/ivardisplay-regular.woff\",\"weight\":\"normal\",\"style\":\"normal\"}],\"adjustFontFallback\":\"Times New Roman\",\"display\":\"fallback\",\"variable\":\"--font-ivardisplay\"}],\"variableName\":\"IVarDisplay\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
